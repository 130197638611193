<template>
    <div>
        <ModuloMovimiento
            :simbololiteralplural="config.simbololiteralplural" 
            :monedasimbolo="config.monedasimbolo"
            :disabledvencimiento="config.disabledvencimiento"
            :userid="config.userid"
        />
    </div>
</template>
    
<script>
    import ModuloMovimiento from './Modulos/ModuloMovimiento.vue'
    
    export default {
        
        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloMovimiento
        }

    }
</script>